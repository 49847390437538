import * as React from 'react';
import { useOpusClient } from './context';
import { useError } from '@aventus/pockethooks/use-error';
import { QuoteBundle, Risk, QuoteState, QuoteRateableState } from '@aventus/platform';
import { ApplicationError } from '@aventus/errors';

export const useNextQuoteIntelligentQuote: UseNextQuoteIntelligentQuote = function (
  risk,
  riskState,
  confirm,
  nextQuoteType,
  quoteState,
  quoteRateableState,
  riskHash
) {

  const opus = useOpusClient();
  const { throwError } = useError();

  const [ intelligentQuoteBundle, setQuoteBundle ] = React.useState<QuoteBundle | undefined>(undefined);
  const [ isGettingQuoteBundle, setIsGettingQuoteBundle ] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async function () {

      if (risk && riskState && quoteRateableState === 'Rateable') {

        setIsGettingQuoteBundle(true);

        try {
          let _intelligentQuoteBundle;

          switch (nextQuoteType) {

            case 'new':
              _intelligentQuoteBundle = await opus.nextQuoteIntelligentQuoteForNew(
                risk,
                riskState,
                confirm
              );
              break;

            case 'adjust':
              _intelligentQuoteBundle = await opus.nextQuoteIntelligentQuoteForAdjust(
                risk,
                riskState,
                confirm
              );
              break;

            case 'renew':
              _intelligentQuoteBundle = await opus.nextQuoteIntelligentQuoteForRenew(
                risk,
                riskState,
                confirm
              );
              break;
            default:
              throwError(
                new ApplicationError(
                  'The `nextQuoteType` argument was not recognised.'
                )
              );
          }

          setQuoteBundle(_intelligentQuoteBundle);

        } catch (error) {
          throwError(error);
        }

        setIsGettingQuoteBundle(false);

      }

    }());
  }, [ quoteState, quoteRateableState, riskHash ]);

  return {
    intelligentQuoteBundle,
    isGettingQuoteBundle
  };

}

export type UseNextQuoteIntelligentQuote = (
  risk: Risk,
  riskState: string,
  confirm: boolean,
  nextQuoteType: NextQuoteType,
  quoteState: QuoteState,
  quoteRateableState: QuoteRateableState,
  riskHash: string
) => {
  intelligentQuoteBundle: QuoteBundle | undefined;
  isGettingQuoteBundle: boolean
}

export type NextQuoteType =
  'new' |
  'adjust' |
  'renew' |
  'additional';
